import React from 'react';
import { useRouter } from 'next/router';

import routes from '@constants/routes';
import Helpers from '@utils/helpers';
import AuthSession from '@utils/auth-session';

//TODO: this page needs as index.html for correct work of the app
const IndexPage = () => {
  const router = useRouter();
  const isLoggedIn = AuthSession.isTokenSet();

  if (Helpers.isClient() && isLoggedIn) {
    router.push(routes.debitCards.index);
  }

  if (Helpers.isClient() && !isLoggedIn) {
    router.push(routes.auth.login);
  }

  return <div></div>;
};

export default IndexPage;
